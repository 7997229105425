import {
  cancel,
  delay,
  fork,
  put,
  select,
  takeEvery,
  takeLatest
} from "redux-saga/effects";

import { actions } from "./ducks";
import { requestMiddleware, uploadFileMiddleware } from "helpers/api";
import {
  addLayerProvider,
  getProviderInferenceStatus,
  prepareProviderInference,
  removeLayerProvider,
  removeProviderInference,
  reorderLayerProvider,
  runProviderInference,
  stopProviderInference
} from "services/api/layer-providers";
import { InferenceStatus } from "scenes/MapPage/ducks";
import { runInference, stopInference } from "services/api/map";
import { LayerAlias, MULTISPECTRAL_OPTIONS, UnitTypes } from "constant";
import features from "features";

const CHECK_STATUS_DELAY_MS = 5000;

function* initProviders({ payload }) {
  const { fetchProviderInferenceStatusRequest, setLayerIsActive } = actions;

  const { providers } = payload;

  if (providers)
    for (const provider of providers) {
      const newPayload = {
        params: { ...payload.params, providerId: provider.id }
      };

      yield put(fetchProviderInferenceStatusRequest(newPayload));
      // setLayerIsActive is called with {isActive:false} to hide tiles
      // after user creates, deletes or reorders providers we rerender tiles on map by calling setLayerIsActive with {isActive:true}
      yield put(
        setLayerIsActive({
          params: { alias: payload.params.alias },
          fields: {
            isActive: false
          }
        })
      );
    }
}

function* createProvider({ payload }) {
  const request = addLayerProvider;
  const isFile = !!payload.fields.file;

  const { createProviderSuccess: setSuccess, createProviderError: setError } =
    actions;

  function* onSuccess(res) {
    const newRes = JSON.parse(JSON.stringify(res));
    const provider = newRes.providers.pop();

    const newFields = isFile
      ? {
          ...payload.fields
        }
      : {
          project_id: parseInt(provider.data.project),
          task_id: provider.data.task
        };

    const newPayload = {
      ...payload,
      params: { ...payload.params, providerId: provider.id },
      fields: newFields
    };

    yield setProviderInference({ payload: newPayload });
  }

  if (payload.fields.file) {
    const newPayload = {
      ...payload,
      fields: { file_name: payload.fields.file.name }
    };

    yield requestMiddleware({
      ...newPayload,
      request,
      setSuccess,
      setError,
      onSuccess
    });
  } else {
    yield requestMiddleware({
      ...payload,
      request,
      setSuccess,
      setError,
      onSuccess
    });
  }
}

function* deleteProviderInference({ payload }) {
  const request = removeProviderInference;

  const { deleteProviderError: setError } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setError,
    isReturnRequestPayload: true
  });

  yield deleteProvider({ payload });
}

function* reorderProvider({ payload }) {
  const request = reorderLayerProvider;

  const { reorderProviderSuccess: setSuccess, reorderProviderError: setError } =
    actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError
  });
}

function* fetchProviderInferenceStatus({ payload }) {
  // yield requestMiddleware({
  //   ...payload,
  //   request,
  //   setSuccess,
  //   setError
  // });

  const request = getProviderInferenceStatus;

  const {
    fetchProviderInferenceStatusSuccess: setSuccess,
    fetchProviderInferenceStatusError: setError
    // checkInferenceStatusDone,
    // setLayerIsActive
  } = actions;

  let reqCount = 0;
  let isDone = false;

  function* onSuccess(res) {
    const { data } = res;

    if (data?.status) {
      const { status } = data;

      const isSuccess = InferenceStatus.DONE === status;
      const isError = InferenceStatus.FAILED === status;
      const isPrepared = InferenceStatus.PREPARED === status;
      const isStopped = InferenceStatus.STOPPED === status;
      isDone = isSuccess || isError || isPrepared || isStopped;

      if (isDone) {
        delete tasks[payload.params.providerId];
      }

      // if (isDone) {
      //   yield put(
      //     yield checkInferenceStatusDone({
      //       showResult: isDone && reqCount > 1,
      //       alias: payload.params.alias
      //     })
      //   );
      // }
      // if (isSuccess)
      //   yield put(
      //     yield setLayerIsActive({ ...payload, fields: { isActive: true } })
      //   );
    }
  }

  const onError = () => {
    isDone = true;
  };

  while (!isDone) {
    reqCount = reqCount + 1;

    yield requestMiddleware({
      ...payload,
      request,
      setSuccess,
      setError,
      onSuccess,
      onError,
      isReturnRequestPayload: true
    });

    yield delay(CHECK_STATUS_DELAY_MS);
  }
}

function* deleteProvider({ payload }) {
  const request = removeLayerProvider;

  const { deleteProviderSuccess: setSuccess, setLayerIsActive } = actions;

  function* onSuccess() {
    const { alias } = payload.params;

    yield put(
      setLayerIsActive({ params: { alias }, fields: { isActive: true } })
    );
  }

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    onSuccess
  });
}

function* setProviderInference({ payload }) {
  const request = prepareProviderInference;

  const {
    fetchProviderInferenceStatusRequest,

    setUploadInferenceFileCancelEvent: getUploadCancelExecutor,
    setUploadInferenceFileProgress: setUploadProgress
  } = actions;

  function* onSuccess() {
    yield put(fetchProviderInferenceStatusRequest(payload));
  }

  if (payload.fields.file) {
    yield uploadFileMiddleware({
      ...payload,
      request,
      onSuccess,
      getUploadCancelExecutor,
      setUploadProgress
    });
  } else {
    yield requestMiddleware({
      ...payload,
      request,
      onSuccess
    });
  }
}

// const tasks = {};
// function* watchProviderInferenceStatus({ payload }) {
//   if (!tasks[payload.params.providerId]) {
//     const { clearState } = actions;

//     const task = yield fork(fetchProviderInferenceStatus, { payload });

//     tasks[payload.params.providerId] = task.id;

//     yield takeLatest(clearState, function* () {
//       yield cancel(task);
//       delete tasks[payload.params.providerId];
//     });
//   }
// }
const tasks = {};
function* watchProviderInferenceStatus({ payload }) {
  const { providerId } = payload.params;

  // Якщо таска вже існує, скасувати її
  if (tasks[providerId]) {
    yield cancel(tasks[providerId]);
    delete tasks[providerId];
  }

  // Створити нову таску
  const task = yield fork(fetchProviderInferenceStatus, { payload });

  // Зберегти ідентифікатор таски
  tasks[providerId] = task;

  const { clearState } = actions;

  // Слухати на clearState і скасувати таску при його виклику
  yield takeLatest(clearState, function* () {
    yield cancel(task);
    delete tasks[providerId];
  });
}

function* startProviderInference({ payload }) {
  const request = runProviderInference;
  const {
    startProviderInferenceSuccess: setSuccess,
    startProviderInferenceError: setError,
    fetchProviderInferenceStatusRequest
  } = actions;

  function* onSuccess() {
    yield put(fetchProviderInferenceStatusRequest(payload));
  }

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onSuccess,
    isReturnRequestPayload: true
  });
}

function* cancelProviderInference({ payload }) {
  const request = stopProviderInference;
  const {
    cancelProviderInferenceSuccess: setSuccess,
    cancelProviderInferenceError: setError,
    fetchProviderInferenceStatusRequest
  } = actions;

  function* onSuccess() {
    yield put(fetchProviderInferenceStatusRequest(payload));
  }

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    onSuccess,
    isReturnRequestPayload: true
  });
}

function* startSensorFusingInference({ payload }) {
  const request = runInference;
  const {
    startSensorFusingInferenceSuccess: setSuccess,
    startSensorFusingInferenceError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    isReturnRequestPayload: true
  });
}

function* cancelSensorFusingInference({ payload }) {
  const request = stopInference;
  const {
    cancelSensorFusingInferenceSuccess: setSuccess,
    cancelSensorFusingInferenceError: setError
  } = actions;

  yield requestMiddleware({
    ...payload,
    request,
    setSuccess,
    setError,
    isReturnRequestPayload: true
  });
}

function* setLayersDefault({ payload }) {
  const { setLayerIsActive } = actions;

  yield put(
    setLayerIsActive({
      params: { ...payload.params, alias: payload.layers[0].alias },
      fields: { isActive: true }
    })
  );
}

//TODO discuss
function findMinMaxRescale(arr) {
  if (arr.length === 0) return { minRescaleMin: null, maxRescaleMax: null };
  let minRescaleMin = arr[0].data?.rescale_min;
  let maxRescaleMax = arr[0].data?.rescale_max;
  for (let i = 1; i < arr.length; i++) {
    if (arr[i].data?.rescale_min < minRescaleMin) {
      minRescaleMin = arr[i].data?.rescale_min;
    }
    if (arr[i].data?.rescale_max > maxRescaleMax) {
      maxRescaleMax = arr[i].data?.rescale_max;
    }
  }
  return { minRescaleMin, maxRescaleMax };
}
function* handleSetLayerIsActive({ payload }) {
  const { layers } = yield select((state) => state.layersManager);
  const { id } = yield select((state) => state.map);
  const { alias } = payload.params;

  if (alias === LayerAlias.MULTISPECTRAL) {
    const { setLayerParams } = actions;

    const layerData = layers.find((layer) => layer.alias === alias);
    const { minRescaleMin, maxRescaleMax } = findMinMaxRescale(
      layerData.providers
    );
    const params = MULTISPECTRAL_OPTIONS(minRescaleMin, maxRescaleMax)[0]
      .params;
    yield put(yield setLayerParams({ params, alias }));
  }

  if (payload.fields.isActive)
    yield put(
      // yield features.unitsManager.actions.fetchMarkersRequest({
      //   params: { ...payload.params, id }
      // })
      yield features.unitsManager.actions.fetchUnitsRequest({
        params: {
          projectId: id,
          type: UnitTypes.POINT,
          alias
        }
      })
    );
}

function* handleClearProvidersState({ payload }) {
  const { setLayerIsActive } = actions;
  yield put(
    setLayerIsActive({
      params: { alias: payload },
      fields: { isActive: true }
    })
  );
}

export default function* watchSaga() {
  yield takeLatest(actions.setProviders, initProviders);
  yield takeLatest(actions.createProviderRequest, createProvider);
  yield takeLatest(actions.deleteProviderRequest, deleteProviderInference);
  yield takeLatest(actions.reorderProviderRequest, reorderProvider);
  yield takeLatest(
    actions.startProviderInferenceRequest,
    startProviderInference
  );
  yield takeLatest(
    actions.cancelProviderInferenceRequest,
    cancelProviderInference
  );
  yield takeEvery(
    actions.fetchProviderInferenceStatusRequest,
    watchProviderInferenceStatus
  );
  yield takeLatest(
    actions.startSensorFusingInferenceRequest,
    startSensorFusingInference
  );
  yield takeLatest(
    actions.cancelSensorFusingInferenceRequest,
    cancelSensorFusingInference
  );
  yield takeLatest(actions.setLayers, setLayersDefault);
  yield takeLatest(actions.setLayerIsActive, handleSetLayerIsActive);
  yield takeLatest(actions.clearProvidersState, handleClearProvidersState);
}
