import cn from "classnames";
import { Form, FormikProvider, useFormik } from "formik";
import L from "leaflet";
import { useCallback, useEffect, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { Marker, useMap } from "react-leaflet";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import { ddRegex, dmsRegex, MapModesEnum } from "constant";
import { convertDMSToDD } from "helpers/convertDMSToDD";
import usePointHandler from "scenes/MapPage/usePointHandler";
import ClickToSetCoordinates from "./ClickToSetCoordinates";
import { RootStateInterface } from "reducer";
import { MapStateInterface } from "../../ducks";

import CommonButton from "components/buttons/CommonButton/CommonButton";
import IconButton from "components/buttons/IconButton/IconButton";
import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";
import FormField from "components/inputs/FormField/FormField";

import FoundMarkerIcon from "assets/icons/pin-map.svg";
import PlusBlackIcon from "assets/icons/plus-black.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

import styles from "./map-search-point.module.scss";

enum MarkerActions {
  ADD_POINT = "add_point"
}

interface onSearchClick {
  onSearchClick: (mode: MapModesEnum) => () => void;
}

const MapSearchPoint = ({ onSearchClick }: onSearchClick) => {
  const map = useMap();
  const searchRef = useRef(null);
  const { createPoint } = usePointHandler();
  // const dispatch = useDispatch();

  const [markerVisible, setMarkerVisible] = useState(false);

  const { mapMode } = useSelector<RootStateInterface, MapStateInterface>(
    (state) => state.map
  );

  const MarkerIcon = L.divIcon({
    html: renderToString(
      <div className={styles["marker"]}>
        {<img src={FoundMarkerIcon} alt="point marker" />}
        <div className={styles["marker__actions"]}>
          <CommonButton
            icon={PlusBlackIcon}
            attrData={MarkerActions.ADD_POINT}
            className={styles["marker__button"]}
          >
            Add point
          </CommonButton>
        </div>
      </div>
    ),
    iconSize: [22, 35],
    iconAnchor: [11, 35],
    className: "marker-reset"
  });

  useEffect(() => {
    if (searchRef.current) {
      L.DomEvent.disableClickPropagation(searchRef.current);
      L.DomEvent.disableScrollPropagation(searchRef.current);
    }
  }, []);

  const validateCoordinate = (value) => {
    return ddRegex.test(value.trim()) || dmsRegex.test(value.trim());
  };

  const validationSchema = Yup.object({
    lat: Yup.string()
      .required("Latitude is required")
      .test("is-coordinate", "Invalid latitude format", validateCoordinate),
    lng: Yup.string()
      .required("Longitude is required")
      .test("is-coordinate", "Invalid longitude format", validateCoordinate)
  });

  const setPointCoordinates = useCallback(
    (values) => {
      formik.setValues(values);
      setMarkerVisible(true);
    },
    // eslint-disable-next-line
    []
  );

  const handlePointSearch = useCallback(
    (values) => {
      const formattedValues = convertDMSToDD(values);

      if (formattedValues.lat && formattedValues.lng) {
        map.setView([formattedValues.lat, formattedValues.lng], 20);
        setPointCoordinates(formattedValues);
      }
    },
    [map, setPointCoordinates]
  );

  const formik = useFormik({
    validateOnChange: true,
    initialValues: { lat: "", lng: "" },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: handlePointSearch
  });

  const handleCoordinateChange = useCallback(
    (name) => (e) => {
      formik.setFieldValue(name, e.target.value);

      setMarkerVisible(false);
    },
    // eslint-disable-next-line
    []
  );

  const showCreatePointDialog = useCallback(() => {
    createPoint({
      onCreatePointSuccess: formik.resetForm,
      coordinates: formik.values
    });
  }, [createPoint, formik.resetForm, formik.values]);

  const markerClickHandler = useCallback(
    (e) => {
      let target = e.originalEvent.target;

      while (target && target.tagName !== "BUTTON") {
        target = target.parentElement;
      }

      if (target && target.tagName === "BUTTON") {
        const attr = target.dataset.attr;

        if (attr === MarkerActions.ADD_POINT) {
          showCreatePointDialog();
        }
      }
    },
    [showCreatePointDialog]
  );

  return (
    <div className={styles["search"]} ref={searchRef}>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          formik.resetForm();
          setMarkerVisible(false);
          onSearchClick(MapModesEnum.SEARCH_POINT)();
      }}
        icon={<SearchIcon />}
        className={cn(styles["icon-button"], {
          [styles["icon-active-button"]]: mapMode === MapModesEnum.SEARCH_POINT
        })}
      />
      <FormikProvider value={formik}>
        <Form
          className={cn(styles["form"], {
            [styles["form__active"]]: mapMode === MapModesEnum.SEARCH_POINT
          })}
        >
          <FormField
            name={"lat"}
            placeholder="Latitude"
            onChange={handleCoordinateChange("lat")}
            className={styles["search-field"]}
          />
          <FormField
            name={"lng"}
            placeholder="Longitude"
            onChange={handleCoordinateChange("lng")}
            className={styles["search-field"]}
          />
          <PrimaryButton
            type={"submit"}
            children={"Search"}
            className={styles["search-button"]}
          />
        </Form>
      </FormikProvider>
      {mapMode === MapModesEnum.SEARCH_POINT &&
        formik.values.lat &&
        formik.values.lng &&
        markerVisible && (
          <Marker
            position={[Number(formik.values.lat), Number(formik.values.lng)]}
            icon={MarkerIcon}
            eventHandlers={{
              click: markerClickHandler
            }}
          />
        )}
      <ClickToSetCoordinates setCoordinates={setPointCoordinates} />
    </div>
  );
};

export default MapSearchPoint;
