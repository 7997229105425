import cn from "classnames";
import { useEffect, useRef, useState } from "react";

import MinusIcon from "assets/icons/minus-gray.svg";
import PlusIcon from "assets/icons/plus-gray.svg";

import styles from "./expand-block.module.scss";

interface ExpandBlockInterface {
  title: string;
  className?: string;
  children: any;
}

const ExpandBlock = ({ title, className, children }: ExpandBlockInterface) => {
  const contentRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [height, setHeight] = useState("0px");

  const handleExpandedSwitch = (e) => {
    e.preventDefault();
    setIsExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    setHeight(isExpanded ? `${contentRef.current.scrollHeight}px` : "0px");

    // //Fix overflow hidden issue
    // if (isExpanded) {
    //   const transitionDuration = 400;
    //   setTimeout(() => {
    //     contentRef.current.scrollIntoView({
    //       behavior: "smooth",
    //       block: "nearest"
    //     });
    //   }, transitionDuration);
    // }
  }, [isExpanded, children]);

  return (
    <div className={cn(styles["expand-block-container"], className)}>
      <div
        className={styles["expand-block-title-wrapper"]}
        onClick={handleExpandedSwitch}
      >
        <span className={styles["expand-block__title"]}>{title}</span>
        <span className={styles["expand-block__icon"]}>
          <img
            src={isExpanded ? MinusIcon : PlusIcon}
            alt={`button ${isExpanded ? "active" : "hidden"}`}
          />
        </span>
      </div>
      <div
        style={{
          maxHeight: height
        }}
        ref={contentRef}
        className={styles["expand-block__content"]}
      >
        {children}
      </div>
    </div>
  );
};

export default ExpandBlock;
