import { createSlice } from "@reduxjs/toolkit";
import { LayerAlias, UnitTypes } from "constant";
import { initialState } from "./initialState";

const combineUnitsList = (oldUnits: any[], newUnits: any[]) => {
  let combinedArray = [...oldUnits, ...newUnits].reduce((acc, obj) => {
    const existingEntry = acc[obj.id];
    const isVisible = existingEntry?.isVisible ?? obj.isVisible ?? undefined;
    acc[obj.id] = { ...obj, isVisible };
    return acc;
  }, {});

  combinedArray = Object.values(combinedArray);
  return combinedArray;
};

export const mainSlice = createSlice({
  name: "unitsManager",
  initialState,
  reducers: {
    fetchUnitsRequest: (state) => {
      state.isFetchUnitsLoading = true;
    },
    fetchUnitsSuccess: (state, { payload }) => {
      const { units, total } = payload.data;
      const { alias, type } = payload.params;
      const combinedUnits = combineUnitsList(
        state.unitsCollection[alias][type].units,
        units ?? []
      );

      state.unitsCollection[alias][type] = {
        units: combinedUnits,
        total
      };
      state.isFetchUnitsLoading = false;
    },
    fetchUnitsError: (state) => {
      state.isFetchUnitsLoading = false;
    },

    fetchUnitRequest: (state) => {
      state.isFetchUnitsLoading = true;
    },
    fetchUnitSuccess: (state) => {
      state.isFetchUnitsLoading = false;
    },
    fetchUnitError: (state) => {
      state.isFetchUnitsLoading = false;
    },

    createUnitRequest: (state) => {
      state.isUnitCreateLoading = true;
    },
    createUnitSuccess: (state, { payload }) => {
      const { alias, type } = payload.params;

      state.unitsCollection[alias][type].units = [
        ...state.unitsCollection[alias][type].units,
        { ...payload.data }
      ];
      state.unitsCollection[alias][type].total =
        state.unitsCollection[alias][type].total + 1;

      state.isUnitCreateLoading = false;
    },
    createUnitError: (state) => {
      state.isUnitCreateLoading = false;
    },

    updateUnitRequest: (state) => {
      state.isUnitCreateLoading = true;
    },
    updateUnitSuccess: (state, { payload }) => {
      const { alias, type } = payload.params;

      state.unitsCollection[alias][type].units = state.unitsCollection[
        payload.params.alias
      ][payload.params.type].units.map((unit) =>
        String(unit.id) !== String(payload.data.id)
          ? unit
          : { ...unit, ...payload.data }
      );

      state.isUnitCreateLoading = false;
    },
    updateUnitError: (state) => {
      state.isUnitCreateLoading = false;
    },

    deleteUnitRequest: (state) => {
      state.isUnitDeleteLoading = true;
    },
    deleteUnitSuccess: (state, { payload }) => {
      const { alias, type } = payload.params;

      state.isUnitDeleteLoading = false;

      const newUnits = state.unitsCollection[alias][type].units.filter(
        (unit) => String(unit.id) !== String(payload.data.id)
      );

      state.unitsCollection[alias][type].units = newUnits;
      state.unitsCollection[alias][type].total =
        state.unitsCollection[alias][type].total - 1;
    },
    deleteUnitError: (state) => {
      state.isUnitDeleteLoading = false;
    },

    //Unit polygons operations[START]
    setPolygonUnitVisibility: (state, { payload }) => {
      state.unitsCollection[LayerAlias.MANUAL][UnitTypes.POLYGON].units =
        state.unitsCollection[LayerAlias.MANUAL][UnitTypes.POLYGON].units.map(
          (item) =>
            item.id === payload.id
              ? { ...item, isVisible: payload.isVisible }
              : item
        );
    },
    setEditPolygonParameters: (state, { payload }) => {
      state.editedPolygonParameters = payload;
    },
    clearEditPolygonParameters: (state) => {
      state.editedPolygonParameters = initialState.editedPolygonParameters;
    },
    updateStepBackHistory: (state, { payload }) => {
      if (!state.isHistoryFrozen) state.stepBackHistory = payload;
    },
    resetStepBackHistory: (state) => {
      state.stepBackHistory = initialState.stepBackHistory;
    },
    freezeHistory: (state) => {
      state.isHistoryFrozen = true;
    },
    unfreezeHistory: (state) =>  {
      state.isHistoryFrozen = false;
    },
    //Unit polygons operations[END]

    //Unit points operations[START]
    setActiveLayerPoints: (state, { payload }) => {
      state.manualPointLayers = state.manualPointLayers.map((item) =>
        item.value === payload.value
          ? { ...item, isActive: payload.isActive }
          : item
      );
    }
    //Unit points operations[END]
  }
});

export const { actions, reducer } = mainSlice;
