import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { LayerAlias, LAYERS, MULTISPECTRAL_OPTIONS } from "constant";
import features from "features";
import { MapLayerInterface as MapLayerDataInterface } from "scenes/LayersManager/ducks";

import Range from "components/Range/Range";
import CommonButton from "components/buttons/CommonButton/CommonButton";
import ShowHideButton from "components/buttons/ShowHideButton/ShowHideButton";

import CameraIcon from "assets/icons/camera.svg";
import PlayIcon from "assets/icons/play.svg";

import styles from "./map-layer.module.scss";

interface MapLayerInterface extends MapLayerDataInterface {
  isPolygon?: boolean;
  setView?: (...args: any) => void;
}

const MapLayer = ({
  name,
  isActive,
  alias,
  params,
  opacity,
  isPolygon,
  providers,
  setView
}: MapLayerInterface) => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const multispectralOptions = useMemo(() => {
    return MULTISPECTRAL_OPTIONS(
      providers?.[0]?.data?.rescale_min,
      providers?.[0]?.data?.rescale_max
    );
  }, [providers]);

  const isOnlyStart = useMemo(
    () => alias === LayerAlias.SENSOR_FUSION,
    [alias]
  );

  const isInit = useMemo(
    () => providers?.length > 0 || isPolygon,
    [isPolygon, providers?.length]
  );

  const onSetLayerIsActive = useCallback(
    (isActive: boolean) => () => {
      isPolygon
        ? dispatch(features.map.actions.toggleAriaIsActive(alias))
        : dispatch(
            features.layersManager.actions.setLayerIsActive({
              params: { alias },
              fields: { isActive }
            })
          );
    },
    [alias, dispatch, isPolygon]
  );

  const onLayerParamsChange = useCallback(
    (params: string, alias: string) => () => {
      dispatch(
        features.layersManager.actions.setLayerParams({ params, alias })
      );
    },
    [dispatch]
  );

  const onChangeOpacity = useCallback(
    (alias: LayerAlias) => (opacity) => {
      dispatch(
        features.layersManager.actions.setLayerOpacity({ opacity, alias })
      );
    },
    [dispatch]
  );

  const onManageProviders = useCallback(() => {
    const isUseFileManager = LAYERS[alias].isUseFileManager;

    dispatch(
      features.modal.actions.showModal({
        modalType: isUseFileManager
          ? "PROVIDER_FILE_MANAGER_MODAL"
          : "PROVIDER_MANAGER_MODAL",
        modalProps: {
          title: `${LAYERS[alias].name} ${
            isUseFileManager ? "File" : "Orthophoto"
          } Manager`,
          alias,
          id,
          isUseFileManager,
          initProviders: providers,
          onHide: (updatedProviders) => {
            const firstProviderCenter = updatedProviders?.[0]?.data?.center;

            if (firstProviderCenter) {
              setView(
                {
                  lat: firstProviderCenter[1],
                  lng: firstProviderCenter[0]
                },
                firstProviderCenter[2]
              );
            }

            // dispatch(
            //   features.map.actions.updateLayer({
            //     alias,
            //     providers: updatedProviders
            //   })
            // );
          }
        }
      })
    );
  }, [alias, dispatch, id, providers, setView]);

  const startSensorFusingInference = useCallback(() => {
    dispatch(
      features.layersManager.actions.startSensorFusingInferenceRequest({
        params: { id, alias }
      })
    );
  }, [alias, dispatch, id]);

  const cancelSensorFusingInference = useCallback(() => {
    dispatch(
      features.map.actions.cancelInferenceRequest({
        params: { id, alias }
      })
    );
  }, [alias, dispatch, id]);

  return (
    <div className={styles["layer-wrapper"]}>
      <ShowHideButton
        title={name}
        onClick={onSetLayerIsActive(!isActive)}
        isActive={isActive}
        isDisabled={!isInit && !isOnlyStart}
      />
      {!isPolygon && !isOnlyStart && (
        <Range
          value={opacity ?? 100}
          containerClassName={styles["layer__range-container"]}
          isDisabled={!isInit || !isActive}
          onChange={onChangeOpacity(alias)}
        />
      )}
      {alias === LayerAlias.MULTISPECTRAL && (
        <div className={styles["multispectral__options"]}>
          {multispectralOptions.map((item) => {
            return (
              <button
                className={classNames(styles["multispectral__option"], {
                  [styles["multispectral__option--active"]]:
                    params === item.params && isActive
                })}
                onClick={onLayerParamsChange(item.params, alias)}
                key={`layer-param-${item.name}`}
                disabled={!isActive}
              >
                {item.name}
              </button>
            );
          })}
        </div>
      )}

      {!isPolygon && (
        <div className={styles["layer__buttons-container"]}>
          {LayerAlias.SENSOR_FUSION === alias ? (
            <CommonButton
              type="button"
              onClick={
                true ? startSensorFusingInference : cancelSensorFusingInference
                // isInferenceProgress ? cancelInferenceAction : inferenceAction
              }
              icon={PlayIcon}
              // isLoading={}
              // disabled={}
            >
              Start
              {/* {isInferenceProgress ? "Cancel inferencing" : "Start"} */}
            </CommonButton>
          ) : (
            <CommonButton
              type="button"
              onClick={onManageProviders}
              icon={CameraIcon}
            >
              Manage
            </CommonButton>
          )}
        </div>
      )}
    </div>
  );
};
export default MapLayer;
