import L from "leaflet";
import cn from "classnames";
import * as turf from "@turf/turf";
import { useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import features from "features";
import { MapModesEnum, UnitTypes } from "constant";
import { RootStateInterface } from "reducer";
import { MapStateInterface } from "../../ducks";
import { UnitsManagerStateInterface } from "scenes/UnitsManager/interfaces";
import { ensureClosedPolygon } from "helpers/ensureClosedPolygon";
import { convertPercentagesToNumber } from "helpers/convertPercentagesToNumber";
import { LayerAlias } from "constant";

import IconButton from "components/buttons/IconButton/IconButton";
import MapSearchPoint from "../../components/MapSearchPoint/MapSearchPoint";

import { ReactComponent as PenToolIcon } from "assets/icons/pen-tool.svg";
import CursorWhiteArrowIcon from "assets/icons/cursor-white-arrow.svg";

import PlusIcon from "assets/icons/plus-black.svg";

import styles from "./map-toolbar.module.scss";

const MapToolbar = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const toolbarRef = useRef(null);
  const { mapMode } = useSelector<RootStateInterface, MapStateInterface>(
    (state) => state.map
  );

  const { editedPolygonParameters } = useSelector<
    RootStateInterface,
    UnitsManagerStateInterface
  >((state) => state.unitsManager);

  useEffect(() => {
    if (toolbarRef.current) {
      L.DomEvent.disableClickPropagation(toolbarRef.current);
      L.DomEvent.disableScrollPropagation(toolbarRef.current);
    }
  }, []);

  const handleToolClick = useCallback(
    (mode: MapModesEnum) => () => {
      if (
        !editedPolygonParameters.id &&
        mode !== MapModesEnum.POLYGON_CREATION
      ) {
        if (editedPolygonParameters.geo_data.coordinates.length > 2) {
          const polygonCoordinates = ensureClosedPolygon(
            editedPolygonParameters.geo_data.coordinates
          );

          const polygon = turf.polygon([polygonCoordinates]);

          const areaInSquareMeters = turf.area(polygon);

          const fields = {
            ...editedPolygonParameters,
            geo_data: {
              ...editedPolygonParameters.geo_data,
              coordinates: polygonCoordinates
            },
            additional: {
              ...editedPolygonParameters.additional,
              area: areaInSquareMeters,
              stroke_transparency: convertPercentagesToNumber(
                editedPolygonParameters.additional.stroke_transparency
              ),
              fill_transparency: convertPercentagesToNumber(
                editedPolygonParameters.additional.fill_transparency
              )
            }
          };

          dispatch(
            features.unitsManager.actions.createUnitRequest({
              params: {
                projectId: id,
                alias: LayerAlias.MANUAL,
                type: UnitTypes.POLYGON
              },
              fields,
              onSuccess: () => {
                dispatch(features.unitsManager.actions.resetStepBackHistory());
                dispatch(
                  features.unitsManager.actions.clearEditPolygonParameters()
                );
              }
            })
          );
        } else {
          dispatch(features.unitsManager.actions.clearEditPolygonParameters());
        }
      } else {
        dispatch(features.unitsManager.actions.clearEditPolygonParameters());
      }
      dispatch(features.map.actions.changeMapMode(mode));
    },
    [dispatch, editedPolygonParameters, id]
  );

  return (
    <div className={styles["map-toolbar"]} ref={toolbarRef}>
      <IconButton
        icon={CursorWhiteArrowIcon}
        className={cn(styles["icon-button"], {
          [styles["icon-active-button"]]: mapMode === MapModesEnum.DEFAULT
        })}
        onClick={handleToolClick(MapModesEnum.DEFAULT)}
      />
      <IconButton
        icon={<PenToolIcon />}
        className={cn(styles["icon-button"], {
          [styles["icon-active-button"]]:
            mapMode === MapModesEnum.POLYGON_CREATION
        })}
        onClick={handleToolClick(MapModesEnum.POLYGON_CREATION)}
      />
      <div className={styles["separator"]} />
      <MapSearchPoint onSearchClick={handleToolClick} />
      <IconButton icon={PlusIcon} className={styles["icon-button"]} />
    </div>
  );
};

export default MapToolbar;
