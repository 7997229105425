import cn from "classnames";
import { Form, FormikProvider, useFormik } from "formik";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import features from "features";
import { RootStateInterface } from "reducer";

import LightButton from "components/buttons/LightButton/LightButton";
import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";
import ModalWrapper from "components/modals/ModalWrapper/ModalWrapper";

import styles from "./form-modal.module.scss";

interface FormModalInterface {
  title?: string;
  description?: string;
  warningDescription?: boolean;
  isDisableClose?: boolean;
  acceptButtonLabel?: string;
  declineButtonLabel?: string;
  acceptButtonAction?: () => void;
  declineButtonAction?: () => void;
  initialValues: any;
  onSubmit: () => void;
  formContent?: JSX.Element;
  formContentWithContext?: ({
    setFieldValue,
    values,
    submitForm
  }) => JSX.Element;
  validationSchema?: any;
  additionalControlElements: JSX.Element[];
  onHide?: () => void;
  isLoading?: boolean;
  isDone?: boolean;
  containerClassName?: string;
  bodyClassName?: string;
  closeButtonClassName?: string;
}

const FormModal = () => {
  const dispatch = useDispatch();

  const {
    title,
    description,
    warningDescription,
    isDisableClose,
    acceptButtonLabel,
    declineButtonLabel,
    acceptButtonAction,
    declineButtonAction,
    initialValues,
    onSubmit,
    formContentWithContext,
    formContent,
    validationSchema,
    additionalControlElements,
    onHide,
    isLoading,
    isDone,
    containerClassName,
    bodyClassName,
    closeButtonClassName
  } = useSelector<RootStateInterface, FormModalInterface>(
    (state) => state.modal.modalProps
  );

  const formik = useFormik({
    validateOnChange: true,
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema,
    onSubmit
  });

  const handleFormSubmit = () => {
    if (acceptButtonAction) {
      acceptButtonAction();
    }
    formik.submitForm();
  };

  const onDecline = useCallback(() => {
    dispatch(features.modal.actions.hideModal());
  }, [dispatch]);

  useEffect(() => {
    if (initialValues) {
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  return (
    <ModalWrapper
      className={cn(styles["modal-container"], containerClassName)}
      bodyClassName={bodyClassName}
      closeButtonClassName={closeButtonClassName}
      isDisableClose={isDisableClose}
      onHide={onHide}
    >
      <div className={styles["form-modal"]}>
        <div className={styles["form-modal__text-container"]}>
          <h2 className={styles["form-modal__title"]}>{title}</h2>
          {description && (
            <h4
              className={cn(styles["form-modal__description"], {
                [styles["form-modal__description-warning"]]: warningDescription
              })}
            >
              {description}
            </h4>
          )}
        </div>
        <FormikProvider value={formik}>
          <Form className={styles["form"]}>
            <div className={styles["form__values-wrapper"]}>
              {formContent ??
                formContentWithContext({
                  setFieldValue: formik.setFieldValue,
                  values: formik.values,
                  submitForm: formik.submitForm
                })}
            </div>
            <div className={styles["form-modal__controls-wrapper"]}>
              {additionalControlElements}
              {!isDone ? (
                <>
                  <LightButton
                    textSize={14}
                    onClick={declineButtonAction ?? onDecline}
                  >
                    {declineButtonLabel ?? "Cancel"}
                  </LightButton>
                  <PrimaryButton
                    type="button"
                    onClick={handleFormSubmit}
                    textSize={14}
                    isLoading={isLoading}
                    isDisable={isLoading}
                  >
                    {acceptButtonLabel ?? "Accept"}
                  </PrimaryButton>
                </>
              ) : (
                <PrimaryButton textSize={14} onClick={onDecline}>
                  Done
                </PrimaryButton>
              )}
            </div>
          </Form>
        </FormikProvider>
      </div>
    </ModalWrapper>
  );
};
export default FormModal;
