import cn from "classnames";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import features from "features";
import { RootStateInterface } from "reducer";
import { UnitsManagerStateInterface } from "scenes/UnitsManager/interfaces";
import { convertToCorrectPercentage } from "helpers/convertToCorrectPercentage";
import { convertPercentagesToNumber } from "helpers/convertPercentagesToNumber";

import IconButton from "components/buttons/IconButton/IconButton";
import FormField from "components/inputs/FormField/FormField";

import DropIcon from "assets/icons/drop.svg";
import { ReactComponent as AreaIcon } from "assets/icons/square-area-black.svg";

import styles from "./map-create-polygon-modal-form.module.scss";

interface ColorConfiguratorInterface {
  colorSubjectName: string;
  colorsList: Array<any>;
  formValues: any;
  setValue: any;
  opacitySubject: string;
}

const ColorConfigurator = ({
  colorSubjectName,
  colorsList,
  formValues,
  setValue,
  opacitySubject
}: ColorConfiguratorInterface) => {
  const dispatch = useDispatch();

  const { editedPolygonParameters } = useSelector<
    RootStateInterface,
    UnitsManagerStateInterface
  >((state) => state.unitsManager);

  const handleOpacityChange = useCallback(
    (e) => {
      const value = e.target.value;
      const filteredValue = convertToCorrectPercentage(value);

      setValue(`additional.${opacitySubject}`, filteredValue);
      dispatch(
        features.unitsManager.actions.setEditPolygonParameters({
          ...editedPolygonParameters,
          additional: {
            ...editedPolygonParameters.additional,
            [opacitySubject]: filteredValue
          }
        })
      );
    },
    [dispatch, editedPolygonParameters, opacitySubject, setValue]
  );

  return (
    <div className={styles["color-configurator"]}>
      <div className={styles["color-select-block"]}>
        <label className={styles["area-label"]}>{colorSubjectName}</label>
        <label className={styles["area-label"]}>Opacity</label>
        <div className={styles["colors-list"]}>
          {colorsList.map((item, index) => (
            <IconButton
              icon={
                <AreaIcon
                  style={{
                    fill: item.color,
                    fillOpacity: convertPercentagesToNumber(item.opacity)
                  }}
                />
              }
              className={cn(styles["color-button"], {
                [styles["selected-button"]]: item.isActive
              })}
              alt={item.colorName}
              onClick={item.action}
              key={`${item.colorName}-${index}`}
              type="button"
            />
          ))}
        </div>
        <div className={styles["opacity-wrapper"]}>
          <FormField
            name={opacitySubject}
            value={formValues.additional[`${opacitySubject}`]}
            onChange={handleOpacityChange}
            icon={DropIcon}
            className={styles["opacity-field"]}
            maskChar=""
            mask={"999"}
          />
          <span className={styles["percentages-mark"]}>%</span>
        </div>
      </div>
    </div>
  );
};

export default ColorConfigurator;
