import cn from "classnames";
import { ErrorMessage, Field } from "formik";
import React, { ChangeEvent, FocusEvent } from "react";
import InputMask from "react-input-mask";

import styles from "./form-field.module.scss";

interface FormFieldInterface {
  id?: any;
  name: string;
  value?: any;
  readonly?: boolean;
  disabled?: boolean;
  className?: string;
  maskChar?: string;
  maskFormatChars?: Object;
  placeholder?: string;
  errClassName?: string;
  errComponent?: React.ComponentType;
  type?: React.HTMLInputTypeAttribute;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onInput?: (e: FocusEvent<HTMLInputElement>) => void;
  widthByValue?: boolean;
  maxWidth?: string;
  minWidth?: string;
  textAlign?: string;
  as?: any;
  label?: string;
  icon?: string;
  mask?: string;
}

const CustomInputMask = ({ field, form, ...props }) => {
  return <InputMask {...field} {...props} />;
};

const FormField = ({ label, icon, mask, ...props }: FormFieldInterface) => {
  return (
    <div className={cn(styles["form-field-wrapper"], props.className)}>
      {label && (
        <label htmlFor={props.id || props.name} aria-disabled={props.disabled}>
          {label}
        </label>
      )}
      {icon && (
        <div
          className={cn(styles["form-field__icon"], {
            [styles["form-field__icon--disabled"]]: props.disabled
          })}
        >
          <img src={icon} alt={`field-${props.name}-icon`}></img>
        </div>
      )}
      <Field
        {...props}
        className={cn(styles["form-field__input"], {
          [styles["form-field__input--with-icon"]]: icon
        })}
        mask={mask}
        component={CustomInputMask}
      />
      <ErrorMessage
        component="div"
        className={styles["error"]}
        name={props.name}
      />
    </div>
  );
};

export default FormField;
