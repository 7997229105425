import L from "leaflet";
import { useMemo, useEffect, useCallback } from "react";
import { renderToString } from "react-dom/server";
import { Marker, Polygon, useMapEvents } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";

import features from "features";
import { convertPercentagesToNumber } from "helpers/convertPercentagesToNumber";
import { RootStateInterface } from "reducer";
import { UnitsManagerStateInterface } from "scenes/UnitsManager/interfaces";

import styles from "./map-polygon-builder.module.scss";

const MapPolygonBuilder = () => {
  const dispatch = useDispatch();

  const { editedPolygonParameters, stepBackHistory, isHistoryFrozen } =
    useSelector<RootStateInterface, UnitsManagerStateInterface>(
      (state) => state.unitsManager
    );

  const MapClickHandler = () => {
    useMapEvents({
      click: (event) => {
        dispatch(
          features.unitsManager.actions.updateStepBackHistory([
            ...stepBackHistory,
            {
              ...editedPolygonParameters,
              geo_data: {
                ...editedPolygonParameters.geo_data,
                coordinates: [...editedPolygonParameters.geo_data.coordinates]
              }
            }
          ])
        );

        const { lat, lng } = event.latlng;
        dispatch(
          features.unitsManager.actions.setEditPolygonParameters({
            ...editedPolygonParameters,
            geo_data: {
              ...editedPolygonParameters.geo_data,
              coordinates: [
                ...editedPolygonParameters.geo_data.coordinates,
                [lat, lng]
              ]
            }
          })
        );
      }
    });

    return null;
  };

  const MapRightClickHandler = () => {
    useMapEvents({
      contextmenu: () => {
        undoLastAction(isHistoryFrozen);
      }
    });
    return null;
  };

  const undoLastAction = useCallback(
    (isHistoryFrozen) => {
      if (!isHistoryFrozen && stepBackHistory.length > 0) {
        const lastState = stepBackHistory[stepBackHistory.length - 1];
        dispatch(
          features.unitsManager.actions.setEditPolygonParameters(lastState)
        );
        dispatch(
          features.unitsManager.actions.updateStepBackHistory(
            stepBackHistory.slice(0, -1)
          )
        );
      }
    },
    [dispatch, stepBackHistory]
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.code === "KeyZ") {
        undoLastAction(isHistoryFrozen);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [undoLastAction, isHistoryFrozen]);

  const MarkerIcon = L.divIcon({
    html: renderToString(<div className={styles["marker"]} />),
    iconSize: [16, 16],
    className: "marker-reset"
  });

  const updateMarkerPlacement = (index, newCoordinate) => {
    const updatedCoordinates = editedPolygonParameters.geo_data.coordinates.map(
      (coord, i) => (i === index ? newCoordinate : coord)
    );

    dispatch(
      features.unitsManager.actions.updateStepBackHistory([
        ...stepBackHistory,
        {
          ...editedPolygonParameters,
          geo_data: {
            ...editedPolygonParameters.geo_data,
            coordinates: editedPolygonParameters.geo_data.coordinates
          }
        }
      ])
    );

    dispatch(
      features.unitsManager.actions.setEditPolygonParameters({
        ...editedPolygonParameters,
        geo_data: {
          ...editedPolygonParameters.geo_data,
          coordinates: updatedCoordinates
        }
      })
    );
  };

  const removePolygonPoint = useCallback(
    (indexToRemove) => {
      dispatch(
        features.unitsManager.actions.updateStepBackHistory([
          ...stepBackHistory,
          {
            ...editedPolygonParameters,
            geo_data: {
              ...editedPolygonParameters.geo_data,
              coordinates: [...editedPolygonParameters.geo_data.coordinates]
            }
          }
        ])
      );

      dispatch(
        features.unitsManager.actions.setEditPolygonParameters({
          ...editedPolygonParameters,
          geo_data: {
            ...editedPolygonParameters.geo_data,
            coordinates: [
              ...editedPolygonParameters.geo_data.coordinates.filter(
                (_, index) => index !== indexToRemove
              )
            ]
          }
        })
      );
    },
    [dispatch, editedPolygonParameters, stepBackHistory]
  );

  // You need to use a long key that will include all the polygon parameters that can change.
  const generatedPolygonKey = useMemo(
    () =>
      `${editedPolygonParameters.additional.stroke_color}-${editedPolygonParameters.additional.fill_color}-${editedPolygonParameters.additional.stroke_transparency}-${editedPolygonParameters.additional.fill_transparency}`,
    [
      editedPolygonParameters.additional.fill_color,
      editedPolygonParameters.additional.fill_transparency,
      editedPolygonParameters.additional.stroke_color,
      editedPolygonParameters.additional.stroke_transparency
    ]
  );

  return (
    <div className={styles["polygon-builder"]}>
      <MapClickHandler />

      {editedPolygonParameters.geo_data.coordinates.length > 1 && (
        <Polygon
          key={generatedPolygonKey}
          positions={editedPolygonParameters.geo_data.coordinates}
          color={editedPolygonParameters.additional.stroke_color}
          fillColor={editedPolygonParameters.additional.fill_color}
          opacity={convertPercentagesToNumber(
            editedPolygonParameters.additional.stroke_transparency
          )}
          fillOpacity={convertPercentagesToNumber(
            editedPolygonParameters.additional.fill_transparency
          )}
        />
      )}

      {editedPolygonParameters.geo_data.coordinates.length > 0 &&
        editedPolygonParameters.geo_data.coordinates.map((point, index) => (
          <Marker
            key={index}
            position={point}
            icon={MarkerIcon}
            draggable={true}
            eventHandlers={{
              dragend: (event) => {
                const { lat, lng } = event.target.getLatLng();
                updateMarkerPlacement(index, [lat, lng]);
              },
              click: () => removePolygonPoint(index)
            }}
          ></Marker>
        ))}
      <MapRightClickHandler />
    </div>
  );
};

export default MapPolygonBuilder;
